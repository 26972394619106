<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <!--------------------------------------STEPPER-------------------------->
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="pb-0 mb-0"
      >
        <v-card class="white px-auto pb-0 mb-0">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-home-circle"
              >
                HOME INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-home-circle"
              >
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="3"
                complete
                complete-icon="mdi-home-circle"
              >
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="4">
                DOWNLOAD YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <!------------------------------------------------FORM---------------------------------->
    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="mt-0 pt-1"
      >
        <v-card
          class="mt-0 mb-16"
        >
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <v-form
              ref="form"
              class="px-8 font-weight-bold"
              @submit.prevent="submit"
            >
              <v-row>
                <v-col>
                  <div class="title-text text-center mt-2">
                    Pay And Download Your Home Insurance
                  </div>
                </v-col>
              </v-row>
              <!---------------------PRIMARY TRAVELER----------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Mailing Information
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="mailingFirstName"
                      label="First Name"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('mailingFirstName', 46, 'Homeowners Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="mailingLastName"
                      label="Last Name"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('mailingLastName', 47, 'Homeowners Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="mailingEmail"
                      label="Email"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('mailingEmail', 48, 'Homeowners Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <vuetify-google-autocomplete
                    id="map"
                    ref="address"
                    classname="form-control"
                    outlined
                    placeholder="Please type your address"
                    country="us"
                    @placechanged="getAddressData"
                  >
                  </vuetify-google-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address"
                    rules="required"
                  >
                    <v-text-field
                      v-model="mailingAddress"
                      label="Address"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('mailingAddress', 49, 'Homeowners Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="mailingSuite"
                    label="Apartment, suite, etc."
                    @click="clickedFieldGlobal('mailingSuite', 50, 'Homeowners Survey v1')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="City"
                    rules="required"
                  >
                    <v-text-field
                      v-model="mailingCity"
                      label="City"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('mailingCity', 51, 'Homeowners Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="State"
                    rules="required"
                  >
                    <v-text-field
                      v-model="mailingState"
                      label="State"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('mailingState', 52, 'Homeowners Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Zip"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model="mailingZip"
                      v-mask="'####'"
                      label="Zip"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('mailingZip', 53, 'Homeowners Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="checkbox"
                    label="Billing Info Same as Mailing Info"
                    @click="clickedFieldGlobal('Billing Info Same as Mailing Info', 54, 'Homeowners Survey v1')"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <!----------------------Billing Address--------------------->
              <div v-if="!checkbox">
                <v-row>
                  <v-col>
                    <h1 class="primary--text mt-2">
                      Billing Information
                    </h1>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="First Name"
                      rules="required"
                    >
                      <v-text-field
                        v-model="billingFirstName"
                        label="First Name"
                        :error-messages="errors"
                        @click="clickedFieldGlobal('billingFirstName', 55, 'Homeowners Survey v1')"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Last Name"
                      rules="required"
                    >
                      <v-text-field
                        v-model="billingLastName"
                        label="Last Name"
                        :error-messages="errors"
                        @click="clickedFieldGlobal('billingLastName', 55, 'Homeowners Survey v1')"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <v-text-field
                        v-model="billingEmail"
                        label="Email"
                        :error-messages="errors"
                        @click="clickedFieldGlobal('billingEmail', 56, 'Homeowners Survey v1')"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <v-text-field
                        v-model="billingAddress"
                        label="Address"
                        :error-messages="errors"
                        @click="clickedFieldGlobal('billingAddress', 57, 'Homeowners Survey v1')"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="billingSuite"
                      label="Apartment, suite, etc."
                      @click="clickedFieldGlobal('billingSuite', 58, 'Homeowners Survey v1')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="City"
                      rules="required"
                    >
                      <v-text-field
                        v-model="billingCity"
                        label="City"
                        :error-messages="errors"
                        @click="clickedFieldGlobal('billingCity', 59, 'Homeowners Survey v1')"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="State"
                      rules="required"
                    >
                      <v-text-field
                        v-model="billingState"
                        label="State"
                        :error-messages="errors"
                        @click="clickedFieldGlobal('billingState', 60, 'Homeowners Survey v1')"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Zip"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="billingZip"
                        v-mask="'####'"
                        label="Zip"
                        :error-messages="errors"
                        @click="clickedFieldGlobal('billingZip', 61, 'Homeowners Survey v1')"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Payment Details
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Cardholder Name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="cardholderName"
                      outlined
                      label="Cardholder Name"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('cardholderName', 62, 'Homeowners Survey v1')"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Card Number"
                    rules="required"
                  >
                    <v-text-field
                      v-model="cardNumber"
                      v-mask="'####-####-####-####'"
                      outlined
                      label="Card Number (no spaces)"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('cardNumber', 63, 'Homeowners Survey v1')"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Expiration Month"
                    rules="required"
                  >
                    <v-text-field
                      v-model="expMonth"
                      v-mask="'##'"
                      outlined
                      label="Exp Month (MM)"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('expMonth', 64, 'Homeowners Survey v1')"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Expiry Year"
                    rules="required"
                  >
                    <v-text-field
                      v-model="expYear"
                      v-mask="'####'"
                      outlined
                      label="Exp Year (YYYY)"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('expYear', 65, 'Homeowners Survey v1')"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Security Code"
                    rules="required"
                  >
                    <v-text-field
                      v-model="cvv"
                      v-mask="'###'"
                      outlined
                      label="Security Code CVV"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('cvv', 66, 'Homeowners Survey v1')"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="d-flex justify-center text-center">
                    <v-btn
                      large
                      :disabled="invalid"
                      color="primary"
                      class="mb-2 font-weight-bold"
                      @click="submit"
                    >
                      Purchase Policy
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        checkbox: true,
        mailingFirstName: '',
        mailingLastName: '',
        mailingEmail: '',
        mailingAddress: '',
        mailingSuite: '',
        mailingCity: '',
        mailingState: '',
        mailingZip: '',
        billingFirstName: '',
        billingLastName: '',
        billingEmail: '',
        billingAddress: '',
        billingSuite: '',
        billingCity: '',
        billingState: '',
        billingZip: '',
        cardholderName: '',
        expMonth: '',
        expYear: '',
        cardNumber: '',
        cvv: ''

      }
    },
    computed: {
    },
    methods: {
      async submit () {
        let complete = await this.$refs.observer.validate()
        if (!complete) {
          console.log('error submitting form')
        } else {
          let policy = {
            premium: 100.00,
            created: Date.now(),
            type: 'HOME',
            carrierRef: '99999',
            clientkey: '-MqUKDiJv2I5elLBZ6-q',
          }
          console.log('buying from ui ', policy)
          this.$store.dispatch('purchaseHomePolicy', policy)
        }
      },
      getAddressData (addressData, placeResultData, id) {
        this.mailingAddress = addressData.name
        this.mailingCity = addressData.locality
        this.mailingZip = addressData.postal_code
        this.mailingState = addressData.administrative_area_level_1
      }
    },
  }
</script>

<style lang="scss" scoped>
.title-text {
  font-size: 36px;
  font-weight: 400;
  color: #00A1B7;
}

.green-background {
  background-color: #D8FDD7;
}
.sub-title{
  font-size: 20px;
  font-weight: 700;
  color: #00A1B7;
}

</style>
